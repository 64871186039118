import React from 'react';

const CustomSVG = () => {
  

  return (
    <svg 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 285.52 37.43"
      width="100%" 
      height="auto"
      
      style={{
        animation: 'draw 4s ease forwards, colorShift 4.3s ease forwards',
      }}
      strokeLinejoin="round"
      strokeLinecap="round"
    >
      <g id="letter1">
        <path d="M1.21,1.21V0h17.01v1.21h2.43v1.22h1.21v2.43h1.21v4.86h-1.21v2.43h-2.43v2.43h2.43v2.43h1.21v4.86h-1.21v2.43h-1.21v1.21h-2.43v1.21H1.21v-1.21H0V1.21h1.21ZM1.21,2.43v13.36h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v-1.21h8.5v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v-3.65h2.43v-2.43h1.21v-2.43h-1.21v-2.43h-1.21v-1.21h-2.43v-1.22H2.43v1.22h-1.22ZM1.21,18.22h1.22v-1.21h-1.22v1.21ZM14.58,6.07h1.21v4.86h-1.21v1.21h-7.29v-7.29h7.29v1.21ZM13.36,6.07h-4.86v4.86h4.86v-1.21h1.21v-2.43h-1.21v-1.21ZM13.36,15.79h-4.86v4.86h4.86v-1.21h1.21v-2.43h-1.21v-1.21ZM15.79,18.22h1.21v-1.21h-1.21v1.21ZM19.44,17.01h1.21v-1.21h-1.21v1.21Z" />
      </g>
      
      <g id="letter2">
        <path d="M29.16,7.29v-1.21h12.15v1.21h2.43v1.22h1.21v2.43h1.21v14.58h-1.21v1.21h-6.07v-1.21h-1.22v1.21h-8.5v-1.21h-2.43v-1.21h-1.21v-6.08h1.21v-1.21h2.43v-1.21h2.43v-1.21h6.07v-1.21h-1.21v-1.22h-6.07v1.22h-2.43v-1.22h-1.22v-4.86h2.43ZM27.94,8.5v2.43h9.72v1.21h1.22v2.43h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v1.21h1.21v-1.21h-1.21v-1.21h1.21v-2.43h-1.21v-2.43h-1.22v-1.21h-2.43v-1.22h-9.72v1.22h-2.43ZM30.37,18.22h1.21v-1.21h-1.21v1.21ZM34.02,18.22v1.21h-1.21v1.21h1.21v1.21h2.43v-1.21h1.21v-2.43h-3.64ZM34.02,17.01h1.21v-1.21h-1.21v1.21ZM36.45,17.01h1.21v-1.21h-1.21v1.21ZM38.88,17.01h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v1.21Z" />
      </g>

      <g id="letter3">
        <path d="M49.81,7.29v-1.21h6.08v1.21h1.21v-1.21h4.86v1.21h1.21v6.08h-1.21v1.21h-3.65v-1.21h-1.21v12.15h-1.21v1.21h-6.08v-1.21h-1.21V7.29h1.21ZM49.81,8.5v7.29h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v-3.65h3.64v1.22h1.21v-1.22h1.22v-3.64h-1.22v-1.22h-2.43v1.22h-3.65v-1.22h-3.64v1.22h-1.22ZM49.81,18.22h1.22v-1.21h-1.22v1.21Z" />
      </g>

      <g id="letter4">
        <path d="M65.61,7.29v-1.21h6.08v1.21h1.21v-1.21h4.86v1.21h1.21v6.08h-1.21v1.21h-3.65v-1.21h-1.21v12.15h-1.21v1.21h-6.08v-1.21h-1.21V7.29h1.21ZM65.61,8.5v7.29h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v-3.65h3.64v1.22h1.21v-1.22h1.22v-3.64h-1.22v-1.22h-2.43v1.22h-3.65v-1.22h-3.64v1.22h-1.22ZM65.61,18.22h1.22v-1.21h-1.22v1.21Z" />
      </g>

      <g id="letter5">
        <path d="M81.4,1.21V0h6.08v1.21h1.21v4.86h-1.21v1.21h-6.08v-1.21h-1.21V1.21h1.21ZM81.4,9.72v-1.21h6.08v1.21h1.21v15.79h-1.21v1.21h-6.08v-1.21h-1.21v-15.79h1.21ZM81.4,2.43v2.43h1.22v1.21h3.64v-1.21h1.22v-2.43h-1.22v-1.22h-3.64v1.22h-1.22ZM81.4,10.93v4.86h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v-4.86h-1.22v-1.21h-3.64v1.21h-1.22ZM81.4,18.22h1.22v-1.21h-1.22v1.21Z" />
      </g>

      <g id="letter6">
        <path d="M93.55,7.29h2.43v-1.21h12.15v1.21h2.43v1.22h1.21v2.43h1.21v10.93h-1.21v2.43h-1.21v1.21h-2.43v1.21h-12.15v-1.21h-2.43v-1.21h-1.22v-2.43h-1.21v-10.93h1.21v-2.43h1.22v-1.22ZM94.77,9.72h-1.21v2.43h-1.22v3.65h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v-3.65h1.21v-1.21h4.86v1.21h1.21v3.65h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-3.65h-1.21v-2.43h-1.21v-1.21h-2.43v-1.22h-9.72v1.22h-2.43v1.21ZM92.34,18.22h1.22v-1.21h-1.22v1.21ZM103.27,12.15h-2.43v1.22h-1.21v6.07h1.21v1.21h2.43v-1.21h1.22v-6.07h-1.22v-1.22ZM105.7,18.22h1.21v-1.21h-1.21v1.21Z" />
      </g>

      <g id="letter7">
        <path d="M126.36,1.21h2.43V0h13.36v1.21h1.21v1.22h1.21v4.86h-1.21v1.22h-2.43v-1.22h-7.29v1.22h1.21v1.21h2.43v1.21h2.43v1.21h2.43v1.22h1.21v2.43h1.21v6.07h-1.21v2.43h-1.21v1.21h-2.43v1.21h-13.37v-1.21h-1.21v-1.21h-1.21v-4.86h1.21v-1.21h2.43v1.21h7.29v-1.21h-1.21v-1.21h-2.43v-1.21h-2.43v-1.21h-2.43v-1.21h-1.21v-2.43h-1.21v-6.07h1.21v-2.43h1.21v-1.22ZM127.57,3.65h-1.21v2.43h-1.21v3.65h1.21v2.43h1.21v1.22h2.43v1.21h2.43v1.21h1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.22v1.21h1.21v-1.21h-1.21v-1.21h-1.22v-1.21h1.22v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-2.43h-2.43v-1.22h-2.43v-1.21h-2.43v-1.21h-1.21v-3.65h10.93v-2.43h-1.21v-1.21h-1.21v-1.22h-10.93v1.22h-2.43v1.21Z" />
      </g>

      <g id="letter8">
        <path d="M147.01,7.29v-1.21h6.08v1.21h1.21v6.08h1.21v3.64h1.21v2.43h1.21v-2.43h1.21v-3.64h1.21v-6.08h1.21v-1.21h6.08v1.21h1.21v8.5h-1.21v2.43h-1.21v2.43h-1.21v2.43h-1.21v1.22h-1.21v1.21h-1.22v1.21h-1.21v1.21h-1.21v1.21h-1.21v1.22h-1.21v1.21h-2.43v1.21h-7.29v-1.21h-1.21v-3.65h1.21v-1.21h2.43v-1.21h2.43v-1.21h-1.22v-1.22h-1.21v-2.43h-1.21v-2.43h-1.21v-2.43h-1.21V7.29h1.21ZM147.01,8.5v6.07h1.21v1.21h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-1.21h-1.21v-6.07h-1.21v-1.22h-3.65v1.22h-1.21ZM160.38,14.58v1.21h1.21v1.21h1.22v1.21h1.21v-1.21h-1.21v-1.21h-1.22v-1.21h-1.21ZM160.38,18.22h1.21v-1.21h-1.21v1.21ZM161.59,8.5v6.07h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-1.21h1.21v-6.07h-1.21v-1.22h-3.64v1.22h-1.22Z" />
      </g>

      <g id="letter9">
        <path d="M172.53,7.29h2.43v-1.21h10.93v1.21h1.21v4.86h-1.21v1.22h-2.43v-1.22h-4.86v1.22h3.64v1.21h3.65v1.21h1.21v1.21h1.21v6.07h-1.21v1.22h-1.21v1.21h-2.43v1.21h-12.15v-1.21h-1.21v-4.86h1.21v-1.21h2.43v1.21h6.08v-1.21h-4.86v-1.21h-2.43v-1.21h-1.21v-1.21h-1.21v-6.07h1.21v-1.21h1.21v-1.22ZM171.31,10.93v3.65h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h1.22v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h-1.21v-3.65h8.5v-2.43h-1.22v-1.22h-8.5v1.22h-2.43v1.21h-1.21v1.21h-1.21ZM179.82,15.79h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h-1.21v1.21ZM183.46,17.01h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v1.21Z" />
      </g>

      <g id="letter10">
        <path d="M193.18,2.43h1.22v-1.22h3.64v1.22h1.21v3.64h4.86v1.21h1.22v3.65h-1.22v1.21h-4.86v7.29h1.21v1.21h3.64v1.21h1.22v3.65h-1.22v1.21h-9.72v-1.21h-1.22v-1.21h-1.21v-2.43h-1.21v-9.72h-1.21v-1.21h-1.21v-3.65h1.21v-1.21h1.21v-1.21h1.21v-1.21h1.21v-1.21ZM194.4,4.86h-1.22v1.21h-1.21v1.21h-1.21v1.22h-1.21v1.21h1.21v1.21h1.21v4.86h1.21v1.21h1.22v1.21h1.21v-1.21h-1.21v-1.21h-1.22v-1.21h1.22v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-4.86h4.86v-1.21h1.21v-1.21h-1.21v-1.22h-4.86v-3.64h-1.21v-1.21h-1.21v1.21h-1.21v1.21ZM191.97,18.22h1.21v-1.21h-1.21v1.21Z" />
      </g>

      <g id="letter11">
        <path d="M208.98,8.5h1.21v-1.22h2.43v-1.21h9.72v1.21h2.43v1.22h1.21v1.21h1.21v6.07h-1.21v1.21h-1.21v1.21h-9.72v1.21h1.21v1.21h7.29v-1.21h2.43v1.21h1.21v4.86h-2.43v1.21h-13.36v-1.21h-2.43v-1.21h-1.21v-2.43h-1.21v-9.72h1.21v-2.43h1.21v-1.21ZM211.41,9.72h-1.22v1.21h-1.21v2.43h-1.21v2.43h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-1.21h-1.21v-4.86h1.21v-1.21h4.86v1.21h1.21v3.65h1.22v1.21h1.21v-1.21h2.43v-3.65h-1.21v-1.21h-1.21v-1.21h-2.43v-1.22h-7.29v1.22h-2.43v1.21ZM207.76,18.22h1.21v-1.21h-1.21v1.21ZM218.7,10.93h-2.43v1.21h-1.21v2.43h3.64v-1.21h1.21v-1.22h-1.21v-1.21ZM216.27,17.01h1.21v-1.21h-1.21v1.21ZM218.7,17.01h1.21v-1.21h-1.21v1.21ZM219.91,14.58v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21Z" />
      </g>

      <g id="letter12">
        <path d="M230.85,7.29v-1.21h6.08v1.21h1.21v-1.21h10.93v1.21h1.21v-1.21h10.93v1.21h1.21v1.22h1.21v2.43h1.21v14.58h-1.21v1.21h-6.07v-1.21h-1.21v-12.15h-1.22v-1.22h-1.21v1.22h-2.43v12.15h-1.21v1.21h-6.08v-1.21h-1.21v-12.15h-1.21v-1.22h-1.21v1.22h-2.43v12.15h-1.21v1.21h-6.08v-1.21h-1.21V7.29h1.21ZM230.85,8.5v7.29h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v-1.21h1.21v1.21h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-3.65h2.43v-1.21h3.64v1.21h1.21v3.65h1.22v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-3.65h2.43v-1.21h3.65v1.21h1.21v3.65h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v-3.65h-1.21v-2.43h-1.21v-1.21h-1.21v-1.22h-8.5v1.22h-3.64v-1.22h-8.5v1.22h-3.64v-1.22h-3.65v1.22h-1.21ZM230.85,18.22h1.21v-1.21h-1.21v1.21ZM244.21,18.22h1.22v-1.21h-1.22v1.21ZM257.58,18.22h1.21v-1.21h-1.21v1.21Z" />
      </g>

      <g id="letter13">
        <path d="M269.73,7.29h2.43v-1.21h10.93v1.21h1.21v4.86h-1.21v1.22h-2.43v-1.22h-4.86v1.22h3.64v1.21h3.65v1.21h1.21v1.21h1.21v6.07h-1.21v1.22h-1.21v1.21h-2.43v1.21h-12.15v-1.21h-1.21v-4.86h1.21v-1.21h2.43v1.21h6.08v-1.21h-4.86v-1.21h-2.43v-1.21h-1.21v-1.21h-1.21v-6.07h1.21v-1.21h1.21v-1.22ZM268.51,10.93v3.65h1.21v1.21h1.21v1.21h1.22v-1.21h-1.22v-1.21h1.22v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h1.21v1.21h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h-1.21v-3.65h8.5v-2.43h-1.22v-1.22h-8.5v1.22h-2.43v1.21h-1.21v1.21h-1.21ZM277.02,15.79h1.21v1.21h1.21v1.21h1.21v-1.21h-1.21v-1.21h-1.21v-1.21h-1.21v1.21ZM280.66,17.01h1.21v1.21h1.22v-1.21h-1.22v-1.21h-1.21v1.21Z" />
      </g>
    </svg>
  );
};

export default CustomSVG;
