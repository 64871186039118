import React from 'react';
import Logo from '../src/componentes/Logo';  // Tu logo SVG animado
import './main.scss';  // Asegúrate de que Sass esté configurado

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="logo-container">
        <Logo />
      </div>

      {/* Fecha debajo del logo */}
      <div className="date-container">
        <p>Septiembre 28, 2024</p>
      </div>

      {/* Correo debajo de la fecha */}
      <div className="email-container">
        <a href="mailto:info@barrio.systems">info@barrio.systems</a>
      </div>
    </div>
  );
};

export default App;
