import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import CustomSVG from '../assets/b1'; // Asegúrate de que la ruta del SVG sea correcta

const Logo: React.FC = () => {
  useEffect(() => {
    console.log("Logo rendered");
  }, []);

  return (
    <Box component="a" href="/" className="logo">
      <CustomSVG />
    </Box>
  );
};

export default Logo;
